import React from "react";
import "./About.scss";

import { AppWrap, MotionWrap } from "../../wrapper";
import { images } from "../../constants";
import AboutComponent from "../../components/About/AboutComponent";

const About = () => {
  return (
    <>
      <h2 className="head-text">
        This is what makes a <span>Good Developer -</span> <br /> Perfectionism:{" "}
        <span>Incinerating Perfectionism</span>
      </h2>

      <div className="app__profiles">
        <AboutComponent name={"Web Development"} img={images.webDeveloper} />
        <AboutComponent
          name={"Frontend Development"}
          img={images.frontendDeveloper}
        />
        <AboutComponent
          name={"Backend Development"}
          img={images.backendDeveloper}
        />
        <AboutComponent name={"Database Management"} img={images.db} />
        <AboutComponent name={"Cloud Computing"} img={images.cloudcomputing} />
        <AboutComponent
          name={"Blockchain Development"}
          img={images.blockchainDeveloper}
        />
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
