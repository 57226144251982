import React from "react";
import { motion } from "framer-motion";

import "../../container/About/About.scss";

const AboutComponent = (props) => {
  return (
    <motion.div
      whileInView={{ opacity: 1 }}
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.5, type: "tween" }}
      className="app__profile-item"
      key={props.name}
    >
      <img src={props.img} alt={props.name} />
      <h2 className="bold-text" style={{ marginTop: 20, width: 200 }}>
        {props.name}
      </h2>
      {/* <p className="p-text" style={{ marginTop: 10 }}>
              {about.description}
            </p> */}
    </motion.div>
  );
};

export default AboutComponent;
