import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";
import solidity from "../assets/solidity.png";
import circle from "../assets/circle.svg";
import hello from "../assets/hello.svg";
import developer_blue from "../assets/developer-blue.gif";
import logo from "../assets/logo.png";
import bootstrap from "../assets/Bootstrap.png";
import mongoDB from "../assets/mongosb.png";
import mySQL from "../assets/mysql.png";
import reactNative from "../assets/react-native.png";
import unity from "../assets/unity.png";

import asus from "../assets/asus.png";
import bolt from "../assets/bolt.png";
import nb from "../assets/nb.png";
import skype from "../assets/skype.png";
import spotify from "../assets/spotify.png";
import arnavLogo from "../assets/arnav-logo-removebg-preview.png";

import webDeveloper from "../assets/web developer.png";
import backendDeveloper from "../assets/backend_development_about.png";
import frontendDeveloper from "../assets/frontend_about.png";
import blockchainDeveloper from "../assets/blockchain.png";

import diabeticRetinopathy from "../assets/arnavmahajan_11910_semIV.pdf";

import addGoals from "../assets/Add_goal.jpeg";
import guessTheNumber from "../assets/Guess the number.jpeg";
import medChain from "../assets/Med Chain.jpeg";
import boulderGame from "../assets/Boulder Doging Game.png";
import eVoting from "../assets/E-voting.png";
import forum from "../assets/Forum.png";
import posAPI from "../assets/POS API.png";
import ordermanagementSystem from "../assets/Order Approval Details.jpeg";
import inventorymanagementSystem from "../assets/dashboard.png";
import portfolioPage from "../assets/Portfolio_Page.png";
import dr from "../assets/dr.jpg";
import db from "../assets/dbmanagement.png";
import cloudcomputing from "../assets/cloudcomputing.png";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
  db,
  cloudcomputing,
  dr,
  email,
  mobile,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  circle,
  logo,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  solidity,
  hello,
  developer_blue,
  arnavLogo,
  webDeveloper,
  backendDeveloper,
  frontendDeveloper,
  blockchainDeveloper,
  diabeticRetinopathy,
  bootstrap,
  mongoDB,
  mySQL,
  reactNative,
  unity,
  addGoals,
  guessTheNumber,
  medChain,
  eVoting,
  forum,
  boulderGame,
  posAPI,
  ordermanagementSystem,
  inventorymanagementSystem,
  portfolioPage,
};
