import React from "react";
import { motion } from "framer-motion";

import SkillComponent from "../../components/Skill/SkillComponent";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Skills.scss";
import { images } from "../../constants";

const Skills = () => {
  /*   const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const querySkills = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setExperience(data);
    });

    client.fetch(querySkills).then((data) => {
      setSkills(data);
    });
  }, []); */

  return (
    <>
      <h2 className="head-text">
        <span>Skills</span> and <span>Experience</span>
      </h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {/*           {skills.map((skill) => ( */}

          <SkillComponent name={"HTML"} img={images.html} />
          <SkillComponent name={"CSS"} img={images.css} />
          <SkillComponent name={"SASS"} img={images.sass} />
          <SkillComponent name={"Bootstrap"} img={images.bootstrap} />
          <SkillComponent name={"Javascript"} img={images.javascript} />
          <SkillComponent name={"React"} img={images.react} />
          <SkillComponent name={"React Native"} img={images.reactNative} />
          <SkillComponent name={"Node"} img={images.node} />
          <SkillComponent name={"MySQL"} img={images.mySQL} />
          <SkillComponent name={"MongoDB"} img={images.mongoDB} />
          <SkillComponent name={"Solidity"} img={images.solidity} />
          <SkillComponent name={"Git"} img={images.git} />
          <SkillComponent name={"Python"} img={images.python} />
          <SkillComponent name={"C++"} img={images.cpp} />
          <SkillComponent name={"Unity"} img={images.unity} />
          {/*  ))} */}
        </motion.div>

        {/* New Div */}
        <motion.div className="app__skills-exp">
          <motion.div className="app__skills-exp-item" key="2023">
            <div className="app__skills-exp-year">
              <p className="bold-text">2023</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Lead Backend Developer - Grouple - 2023"
                >
                  <h4 className="bold-text">Grouple </h4>
                  <p className="p-text">
                    <b>Lead Backend Developer</b>
                  </p>
                  <p className="p-text">
                    Led the backend development team at Grouple, overseeing the
                    architecture and implementation of scalable web services.
                    Played a key role in optimizing system performance and
                    integrating new features to enhance user experience.
                  </p>
                </motion.div>

                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Backend Developer - Atmoz - 2023"
                >
                  <h4 className="bold-text">Atmoz</h4>
                  <p className="p-text">
                    <b>Backend Developer</b>
                  </p>
                  <p className="p-text">
                    Developed RESTful APIs for HRMS modules with Node.js,
                    improving the response time. Also developed design for MySQL
                    database along with well maintained documentation.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>

          <motion.div className="app__skills-exp-item" key="2022">
            <div className="app__skills-exp-year">
              <p className="bold-text">2022</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Node Js Developer Intern - Advalue - 2022"
                >
                  <h4 className="bold-text">AdValue</h4>
                  <p className="p-text">
                    <b>Node Js Developer Intern</b>
                  </p>
                  <p className="p-text">
                    Formulated extensive order management system for shoe
                    manufacturing industry, with a user firendly UI/UX design
                    and leveraging technological aspects of Node.js.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>

          {/* <motion.div className="app__skills-exp-item" key="2021">
            <div className="app__skills-exp-year">
              <p className="bold-text">2021</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Node Js Developer Intern - AdValue - 2021"
                >
                  <h4 className="bold-text">Node Js Developer Intern</h4>
                  <p className="p-text">AdValue</p>
                </motion.div>
              </>
            </motion.div>
          </motion.div> */}

          <motion.div className="app__skills-exp-item" key="2020">
            <div className="app__skills-exp-year">
              <p className="bold-text">2020</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Unity Developer Intern - HighAvenue - 2020"
                >
                  <h4 className="bold-text">HighAvenue</h4>
                  <p className="p-text">Unity Developer Intern</p>
                  <p className="p-text">
                    Developed an augmented reality app used to create, share and
                    watch short (10s) 3D animated AR videos. Participated in the
                    full software development lifecycle including coding,
                    debugging, and documenting application state flows.
                  </p>
                </motion.div>

                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Unity Developer Intern Visual Respiration - 2020"
                >
                  <h4 className="bold-text">Visual Respiration</h4>
                  <p className="p-text">Unity Developer Intern</p>
                  <p className="p-text">
                    Designed and implemented a C#-based Q&A interface addressing
                    key social challenges faced by the community. Conducted
                    comprehensive debugging and ensured seamless functionality
                    and user-friendly GUI.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
